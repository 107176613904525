import { render, staticRenderFns } from "./V36SF09001B.vue?vue&type=template&id=2b9944d3&"
import script from "./V36SF09001B.vue?vue&type=script&lang=js&"
export * from "./V36SF09001B.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports