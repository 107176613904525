<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: V36SF09001B.vue
 Projeto: SaaSERP2
 Criação do arquivo: 07/02/2022 - 12:34:03
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:42:06
 Modificado por: pedro.rosa
-->
<template>
  <div>
    <canvas id="grafico-linha" />
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  name: "GrafBarra",
  props: {
    dados: {
      type: Array,
      required: true,
    },
    colunas: {
      type: Array,
      required: true,
    },
  },
  data(vm) {
    return {
      i18n: vm.$t("DASHBOARD"),
    };
  },

  mounted() {
    const ctx = document.getElementById("grafico-linha");
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: this.colunas,
        datasets: [
          {
            label: this.i18n.QtdeScans,
            data: this.dados,
            backgroundColor: "#f49636ad",
            borderColor: "#db8832",
            borderWidth: 4,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Chart.js Line Chart - Cubic interpolation mode",
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Value",
            },
            suggestedMin: -10,
            suggestedMax: 200,
          },
        },
      },
    });
  },
};
</script>

<style>
</style>