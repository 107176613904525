<!--
 Copyright SaasTec Labs - 2024. Todos os direitos reservados
 -----
 Arquivo: V36SF0901A.vue
 Projeto: SaaSERP2
 Criação do arquivo: 14/04/2022 - 12:49:02
 Autor: SaaSTec Labs
 -----
 Última modificação: 30/05/2022 - 15:42:14
 Modificado por: pedro.rosa
-->
<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="8"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-card
              width="100%"
              height="200"
              elevation="0"
              class="espacamento"
            >
              <div style="text-align: center; word-break: break-word">
                <v-card-title class="font-weight-bold text-h6 text-secondary">
                  {{ i18n.maisEscan }}
                </v-card-title>
                <img
                  v-if="fotoProdMaisEscaneado"
                  :src="`data:image/png;base64,${fotoProdMaisEscaneado}`"
                  class="img_prod_mais-escaneado"
                >
                <div v-else>
                  <v-icon size="48">
                    no_photography
                  </v-icon>
                </div>
              </div>
              <v-card-text
                v-if="prodMaisEscaneado"
                style="text-align: center"
              >
                {{ i18n.maisEscanCard1 }} {{ prodMaisEscaneado.ce110_ds_prod }}
                {{ i18n.maisEscanCard2 }}
                {{ prodMaisEscaneado.ce045_nm_fantas }}
                {{ i18n.maisEscanCard3 }}
                <span
                  style="font-size: 18px; font-weight: 900"
                >{{ prodMaisEscaneado.tc045_qt_pesq }}
                </span>
                {{
                  prodMaisEscaneado.tc045_qt_pesq > 0
                    ? i18n.maisEscanCard5
                    : i18n.maisEscanCard4
                }}
                {{ i18n.maisEscanCard6 }}
              </v-card-text>
              <v-card-text v-else>
                {{ i18n.maisEscanCardNenhum }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-card
              width="100%"
              height="200"
              elevation="0"
              class="espacamento"
            >
              <div style="text-align: center">
                <v-card-title class="font-weight-bold text-h6 text-secondary">
                  {{ i18n.melhorAval }}
                </v-card-title>
                <img
                  v-if="fotoProdMelhorAval"
                  :src="`data:image/png;base64,${fotoProdMelhorAval}`"
                  class="img_prod_mais-escaneado"
                >
                <div v-else>
                  <v-icon size="48">
                    no_photography
                  </v-icon>
                </div>
              </div>
              <v-card-text
                v-if="prodMelhorAval"
                style="text-align: center"
              >
                {{ prodMelhorAval.ce110_ds_prod }} {{ i18n.melhorAvalCard1 }}
                <span
                  style="font-size: 18px; font-weight: 900"
                >{{ prodMelhorAval.tc006_md_aval }}/5</span>
                {{ i18n.melhorAvalCard2 }} {{ prodMelhorAval.tc006_qt_entr }}
                {{ i18n.melhorAvalCard3 }}
              </v-card-text>
              <v-card-text v-else>
                {{ i18n.melhorAvalCardNenhum }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-card
              width="100%"
              height="200"
              elevation="0"
              class="espacamento"
            >
              <div style="text-align: center; word-break: break-word">
                <v-card-title class="font-weight-bold text-h6 text-secondary">
                  {{ i18n.pedidos }}
                </v-card-title>
                <img
                  v-if="fotoProdPiorAval"
                  :src="`data:image/png;base64,${fotoProdPiorAval}`"
                  class="img_prod_mais-escaneado"
                >
                <div v-else>
                  <v-icon size="48">
                    no_photography
                  </v-icon>
                </div>
              </div>
              <v-card-text
                v-if="prodPiorAval"
                style="text-align: center"
              >
                {{ prodPiorAval.ce110_ds_prod }} {{ i18n.melhorAvalCard1 }}
                <span
                  style="font-weight: 900; font-size: 18px"
                >{{ prodPiorAval.tc006_md_aval }}/5</span>
                {{ i18n.melhorAvalCard2 }} {{ prodPiorAval.tc006_qt_entr }}
                {{ i18n.melhorAvalCard3 }}
              </v-card-text>
              <v-card-text v-else>
                {{ i18n.melhorAvalCardNenhum }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              width="100%"
              height="100%"
              elevation="0"
            >
              <v-card-title class="font-weight-bold text-h6 text-secondary">
                {{ i18n.scansLoja }}
              </v-card-title>
              <div style="padding: 10px 100px">
                <graf-barra
                  v-if="colunasGraf && dadosGraf"
                  :colunas="colunasGraf"
                  :dados="dadosGraf"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          width="100%"
          height="100%"
          elevation="0"
        >
          <v-card-title class="font-weight-bold text-h6 text-secondary">
            {{ i18n.avalProd }}
          </v-card-title>
          <v-data-table
            height="100%"
            :items-per-page="15"
            :headers="cabecalhos"
            :items="prod"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.tc006_md_aval="{ item }">
              <v-rating
                mdall
                readonly
                empty-icon="star_outline"
                full-icon="star"
                half-icon="star_half"
                half-increments
                hover
                :value="item.tc006_md_aval"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const vazio = [{}];
import GrafBarra from "./components/V36SF09001B.vue";
export default {
  components: { GrafBarra },
  data: (vm) => ({
    fill: true,
    color: "#f5863496",
    prodPiorAval: null,
    i18n: vm.$t("DASHBOARD"),
    colunasGraf: undefined,
    dadosGraf: undefined,
    prodMaisEscaneado: undefined,
    radius: 20,
    prodMelhorAval: undefined,
    cabecalhos: [],
    width: 2,
    prod: [],
  }),

  computed: {
    fotoProdMelhorAval() {
      let foto = this.imgDBprod(this.prodMelhorAval);
      return foto.ce107_im_prod;
    },
    fotoProdPiorAval() {
      let foto = this.imgDBprod(this.prodPiorAval);
      return foto.ce107_im_prod;
    },
    fotoProdMaisEscaneado() {
      let foto = this.imgDBprod(this.prodMaisEscaneado);
      return foto.ce107_im_prod;
    },
  },
  async mounted() {
    this.qtdeNovosUsuarios();
    this.avaliacaoProdutosConfig();
    this.qtdeScanProdutosConfig();
    this.qtdeScanLojas();
    this.cabecalhos = [
      {
        text: this.i18n.nome,
        value: "ce110_ds_prod",
      },
      {
        text: this.i18n.QtdeAval,
        value: "tc006_qt_entr",
      },
      {
        text: this.i18n.nota,
        value: "tc006_md_aval",
      },
    ];
  },

  methods: {
    async qtdeScanLojas() {
      let retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360902005",
        params: vazio,
      });
      retorno = retorno.database;
      let colunas = [];
      let dados = [];
      retorno.forEach((v) => {
        colunas.push(v.ce045_nm_fantas);
        dados.push(v.tc045_qt_pesq);
      });
      this.colunasGraf = colunas;
      this.dadosGraf = dados;
    },
    async qtdeScanProdutosConfig() {
      let retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360902004",
        params: vazio,
      });
      retorno = retorno.database;
      this.prodMaisEscaneado = retorno[0];
    },
    async avaliacaoProdutosConfig() {
      let retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360902001",
        params: vazio,
      });
      retorno = retorno.database;
      this.prod = retorno;
      this.prodMelhorAval = retorno[0];

      retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360902003",
        params: vazio,
      });

      this.prodPiorAval = retorno.database[0];
    },
    async qtdeNovosUsuarios() {
      var dataHoje = new Date();
      var strDataHoje = {
        _rDT_INCSIS: `${dataHoje.getFullYear()}-${dataHoje.getMonth() + 1}%`,
      };
      //eslint-disable-next-line
      let qtdeNovoUsuarios = await this.request({
        metodo: 'get',
        sp: "SP_CE360704004",
        params: strDataHoje,
      });
      qtdeNovoUsuarios = qtdeNovoUsuarios.database;
    },
    async imgDBprod(prod) {
      if (!prod) return null;
      let req = { _rCE110_CD_PROD: prod.ce110_cd_prod };
      let retorno = await this.request({
        metodo: 'get',
        sp: "SP_CE360902002",
        params: req,
      });
      retorno = retorno.database;
      let produto = {
        ...prod,
        ce107_im_prod: retorno[0].ce107_im_prod
          ? JSON.parse(retorno[0].ce107_im_prod)
          : null,
      };
      return produto;
    },
  },
};
</script>

<style lang="scss" src='./styles.scss' >
</style>